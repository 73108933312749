import React, { useEffect, useContext, useCallback, useState } from "react"
import { navigate } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import Seo from "components/seo"
// import sampleimg from "images/sampleimg.png"
import * as styles from "pages/login.module.css"
import json from "contents/login.json"
import { login } from "utils/memberUtils"
import CheckBox from "components/tools/checkBox.js"
import Logo from "components/tools/logo.js"
import api from "apis"
import { getIsApp, getIsMobile, getJson } from "utils"
import loginChecked from "images/loginChecked.png"
import modalContext from "context/modalContext"
import alertContext from "context/alertContext"

const LoginPage = () => {
  const { modalOpen, setmodalOpen } = useContext(modalContext)
  const [isLogin, setisLogin] = useState(true)
  const { alertOpen, setAlertOpen } = useContext(alertContext)

  useEffect(() => {
    ;(async () => {
      const isLogin = await api.loginCheck()
      const isApp = getIsApp()
      setisLogin(isLogin)

      if (isApp) isLogin && navigate("/front")
      else isLogin && navigate("/member/success/login")
    })()
  }, [])

  const enterSearch = useCallback(
    async e => {
      if (e.code === "Enter" || window.event.keyCode == 13) {
        console.log("enter", alertOpen)

        if (alertOpen) setAlertOpen(false)
        else await login()
      }
    },
    [alertOpen]
  )

  const [signUp, serchIDPW] = json.Menu
  if (isLogin) return null
  else
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.box}>
          <div className={styles.introBack}>
            <div className={styles.introImg}></div>
            {/* <img src={introImg} className={styles.introImg} /> */}
          </div>
          <div className={styles.indexBackground}>
            <div>
              <div style={{ marginBottom: "10px" }}>
                <Logo w={179.45} h={40} navCancel={true} />
              </div>
              <div className={styles.con}>
                <div style={{ cursor: "pointer" }}>
                  신규 사용자이신가요?{" "}
                  <span
                    onClick={() => {
                      navigate(`${signUp.url}`)
                    }}
                  >
                    {signUp.title} 하기
                  </span>
                </div>
              </div>
              <input
                type="text"
                placeholder="아이디를 입력해주세요."
                name="loginInput"
                style={{
                  paddingLeft: "10px",
                }}
              />
              <input
                type="password"
                placeholder="비밀번호를 입력해주세요."
                name="loginInput"
                onKeyPress={enterSearch}
                style={{
                  paddingLeft: "10px",
                }}
              />
              <div
                className={styles.loginBox}
                onClick={login}
                onKeyPress={enterSearch}
                tabIndex="0"
              >
                로그인
              </div>
              <div className={styles.loginMenu}>
                <div style={{ display: "flex" }}>
                  <CheckBox id="autoLoginCheck" className={"loginCheckBox"} />
                  <img src={loginChecked} alt="" />
                  <div
                    style={{
                      paddingLeft: "12px",
                    }}
                  >
                    자동 로그인하기
                  </div>
                </div>
                <div className={styles.con}>
                  <div
                    onClick={() => {
                      navigate(`${serchIDPW.url}`)
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {serchIDPW.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default LoginPage
